var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mt-5 mx-auto",attrs:{"loading":_vm.loading,"max-width":"700px"}},[_c('Page-header',{attrs:{"title":_vm.$t('accountTree.accounts'),"icon":"mdi-file-tree"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary darken-1"},nativeOn:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add"))+" ")],1)]},proxy:true}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary darken-1"}},[_c('v-card-title',[_c('span',[_vm._v(_vm._s((_vm.editedIndex === -1 ? _vm.$t("add") : _vm.$t("edit")) + " " + _vm.$t("accountTree.account")))])])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","dense":"","rules":_vm.rules,"label":_vm.$t('accountTree.accountName')},model:{value:(_vm.editedItem.accountName),callback:function ($$v) {_vm.$set(_vm.editedItem, "accountName", $$v)},expression:"editedItem.accountName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","hide-details":"","disabled":_vm.editedItem.parentGuid ==
                                                '00000000-0000-0000-0000-000000000000',"items":_vm.accountTreeList,"rules":_vm.rules,"item-value":"accountGuid","item-text":"nameCode","label":_vm.$t(
                                                'accountTree.accountParrentName'
                                            )},on:{"change":_vm.generateCode},model:{value:(_vm.editedItem.parentGuid),callback:function ($$v) {_vm.$set(_vm.editedItem, "parentGuid", $$v)},expression:"editedItem.parentGuid"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","rules":_vm.rules,"label":_vm.$t('accountTree.accountCode')},model:{value:(_vm.editedItem.accountCode),callback:function ($$v) {_vm.$set(_vm.editedItem, "accountCode", $$v)},expression:"editedItem.accountCode"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"top":"","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","rotate":""},on:{"click":_vm.generateCode}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( "accountTree.autoCode" )))])])],1)],2)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary darken-1 white--text","disabled":!_vm.valid,"min-width":100,"loading":_vm.loading},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"text":"","color":"red"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete,"toolBarColor":"red darken-2"}}),_c('v-text-field',{staticClass:"pa-5",attrs:{"outlined":"","loading":_vm.loading,"dense":"","label":_vm.$t('search'),"flat":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.accountTree)?_c('v-treeview',{ref:"treeReference",staticClass:"px-5 pb-5",attrs:{"active":_vm.active,"items":_vm.accountTree,"open":_vm.open,"search":_vm.search,"activatable":"","open-on-click":"","rounded":"","item-text":"accountName","item-key":"accountGuid","item-children":"children"},on:{"update:active":function($event){_vm.active=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
                                                    var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(item.parentGuid == "00000000-0000-0000-0000-000000000000" ? "mdi-folder-outline" : "mdi-circle-small")+" ")])]}},{key:"label",fn:function(ref){
                                                    var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"align-self":"center"}},[_vm._v(" "+_vm._s(item.accountCode)+" - "+_vm._s(item.accountName)+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.loading,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(_vm.isInRole(17))?_c('v-list-item',[_c('v-btn',{attrs:{"loading":_vm.loading,"text":""},on:{"click":function($event){return _vm.addItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("add")))])],1)],1):_vm._e(),(_vm.isInRole(17))?_c('v-list-item',[_c('v-btn',{attrs:{"loading":_vm.loading,"text":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("edit")))])],1)],1):_vm._e(),(_vm.isInRole(18))?_c('v-list-item',[_c('v-btn',{attrs:{"loading":_vm.loading,"text":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"red","small":""}},[_vm._v(" mdi-delete-outline ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("delete")))])],1)],1):_vm._e()],1)],1)],1)],1)]}}],null,false,3085229133)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }