<template>
    <v-container>
        <v-card :loading="loading" class="mt-5 mx-auto" max-width="700px">
            <Page-header
                :title="$t('accountTree.accounts')"
                icon="mdi-file-tree"
            >
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-header>
            <!--Save account popup-->
            <v-dialog v-model="dialog" persistent max-width="500px">
                <v-toolbar dark color="primary darken-1">
                    <v-card-title>
                        <span>{{
                            (editedIndex === -1 ? $t("add") : $t("edit")) +
                                " " +
                                $t("accountTree.account")
                        }}</span>
                    </v-card-title>
                </v-toolbar>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            outlined
                                            hide-details
                                            dense
                                            v-model="editedItem.accountName"
                                            :rules="rules"
                                            :label="
                                                $t('accountTree.accountName')
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            outlined
                                            dense
                                            @change="generateCode"
                                            v-model="editedItem.parentGuid"
                                            hide-details
                                            :disabled="
                                                editedItem.parentGuid ==
                                                    '00000000-0000-0000-0000-000000000000'
                                            "
                                            :items="accountTreeList"
                                            :rules="rules"
                                            item-value="accountGuid"
                                            item-text="nameCode"
                                            :label="
                                                $t(
                                                    'accountTree.accountParrentName'
                                                )
                                            "
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            :rules="rules"
                                            v-model="editedItem.accountCode"
                                            :label="
                                                $t('accountTree.accountCode')
                                            "
                                        >
                                            <template slot="append">
                                                <v-tooltip
                                                    top
                                                    transition="fab-transition"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-icon
                                                            color="primary"
                                                            rotate
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="
                                                                generateCode
                                                            "
                                                        >
                                                            mdi-refresh
                                                        </v-icon>
                                                    </template>
                                                    <span>{{
                                                        $t(
                                                            "accountTree.autoCode"
                                                        )
                                                    }}</span>
                                                </v-tooltip>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                color="primary darken-1 white--text"
                                @click="save"
                                :disabled="!valid"
                                :min-width="100"
                                :loading="loading"
                            >
                                <v-icon>mdi-content-save-outline</v-icon>
                                {{ $t("save") }}
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                :loading="loading"
                                text
                                color="red"
                                @click="close"
                            >
                                {{ $t("cancel") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
            <!--Delete account popup-->
            <confirm-dialog
                :openDialog="dialogDelete"
                :onClicked="deleteItemConfirm"
                :onClose="closeDelete"
                toolBarColor="red darken-2"
            ></confirm-dialog>
            <!--Search account-->
            <v-text-field
                outlined
                :loading="loading"
                class="pa-5"
                dense
                v-model="search"
                :label="$t('search')"
                flat
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
                append-icon="mdi-magnify"
            >
            </v-text-field>
            <v-treeview
                v-if="accountTree"
                ref="treeReference"
                :active.sync="active"
                :items="accountTree"
                :open.sync="open"
                :search="search"
                activatable
                open-on-click
                rounded
                item-text="accountName"
                item-key="accountGuid"
                item-children="children"
                class="px-5 pb-5"
            >
                <template v-slot:prepend="{ item }">
                    <v-icon>
                        {{
                            item.parentGuid ==
                            "00000000-0000-0000-0000-000000000000"
                                ? "mdi-folder-outline"
                                : "mdi-circle-small"
                        }}
                    </v-icon>
                </template>
                <template v-slot:label="{ item }">
                    <v-row>
                        <v-col align-self="center">
                            {{ item.accountCode }} - {{ item.accountName }}
                        </v-col>
                        <v-col cols="auto" class="">
                            <v-menu bottom left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        :loading="loading"
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-if="isInRole(17)">
                                        <v-btn
                                            :loading="loading"
                                            text
                                            @click="addItem(item)"
                                        >
                                            <v-list-item-icon>
                                                <v-icon small class="mx-1">
                                                    mdi-plus
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>{{
                                                $t("add")
                                            }}</v-list-item-title>
                                        </v-btn>
                                    </v-list-item>
                                    <v-list-item v-if="isInRole(17)">
                                        <v-btn
                                            :loading="loading"
                                            text
                                            @click="editItem(item)"
                                        >
                                            <v-list-item-icon>
                                                <v-icon small class="mx-1">
                                                    mdi-pencil
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>{{
                                                $t("edit")
                                            }}</v-list-item-title>
                                        </v-btn>
                                    </v-list-item>
                                    <v-list-item v-if="isInRole(18)">
                                        <v-btn
                                            :loading="loading"
                                            text
                                            @click="deleteItem(item)"
                                        >
                                            <v-list-item-icon>
                                                <v-icon
                                                    color="red"
                                                    small
                                                    class="mx-1"
                                                >
                                                    mdi-delete-outline
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>{{
                                                $t("delete")
                                            }}</v-list-item-title>
                                        </v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                    </v-row>
                </template>
            </v-treeview>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: { ConfirmDialog, PageHeader },
    data() {
        return {
            active: [],
            open: [],
            valid: true,
            loading: true,
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            branches: [],
            accountTreeList: [],
            accountTree: [],
            editedItem: {
                accountGuid: "",
                accountCode: "",
                parentGuid: "",
                accountName: "",
                treeLevel: 0
            },
            defaultItem: {
                accountCode: "",
                parentGuid: "",
                accountName: ""
            },
            rules: [value => !!value || this.$t("ThisFieldIsRequired")]
        };
    },
    created() {
        if (this.isInRole(16)) {
            this.loading = true;
            this.getData();
        } else this.redirectUnauthorizedUsers();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        generateCode() {
            try {
                if (
                    this.editedItem.parentGuid == undefined ||
                    this.editedItem.parentGuid == null ||
                    this.editedItem.parentGuid == ""
                ) {
                    this.$toast.warning(this.$t("error.SelectParentAccount"));
                } else {
                    this.loading = true;
                    axios
                        .get(
                            "AccountsTree/GenerateCode?id=" +
                                this.editedItem.parentGuid
                        )
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.editedItem.accountCode =
                                    response.data.data;
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        addItem(item) {
            if (item) {
                this.editedItem.parentGuid = item.accountGuid;
                if (item.accountGuid) this.generateCode();
            }
            setTimeout(() => {
                this.dialog = true;
            }, 100);
        },
        editItem(item) {
            this.editedIndex = this.branches.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.branches.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            try {
                var Item = this.branches[this.editedIndex];
                this.loading = true;
                axios
                    .delete("AccountsTree/Delete?id=" + Item.accountGuid)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                        this.getData();
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });

                this.closeDelete();
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        getData() {
            try {
                axios
                    .get("AccountsTree/Get")
                    .then(response => {
                        this.accountTree = [];
                        this.accountTreeList = [];
                        this.branches = response.data.data;

                        for (var i = 0; i < this.branches.length; i++) {
                            var tree = this.branches[i];

                            var children = this.branches.filter(f => {
                                return f.parentGuid == tree.accountGuid;
                            });

                            if (children.length > 0) {
                                children.forEach(child => {
                                    var childNode = {
                                        ...tree,
                                        item: child,
                                        vnode: null
                                    };
                                    this.$refs.treeReference.nodes[
                                        child.accountGuid
                                    ] = childNode;
                                });
                                tree.children = children;
                            }

                            this.accountTreeList.push(tree);

                            if (
                                this.branches[i].parentGuid ==
                                "00000000-0000-0000-0000-000000000000"
                            ) {
                                this.accountTree.push(tree);
                            }
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        save() {
            try {
                if (this.$refs.form.validate()) {
                    if (this.editedIndex == -1) {
                        this.editedItem.accountGuid =
                            "00000000-0000-0000-0000-000000000000";
                    }
                    this.loading = true;
                    axios
                        .post("AccountsTree/Save", this.editedItem)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.close();
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                            this.getData();
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );

                            this.getData();
                            console.log(e);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        }
    }
};
</script>
